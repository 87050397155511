export const getCookie = (c_name: string) => {
  if (document.cookie.length > 0) {
    let c_start = document.cookie.indexOf(c_name + '=');
    if (c_start != -1) {
      c_start = c_start + c_name.length + 1;
      let c_end = document.cookie.indexOf(';', c_start);
      if (c_end == -1) {
        c_end = document.cookie.length;
      }
      return unescape(document.cookie.substring(c_start, c_end));
    }
  }
  return '';
};

export const createCookie = (name: string, value: string, days: number, hostname?: string) => {
  let expires;
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  } else {
    expires = '';
  }
  document.cookie =
    encodeURIComponent(name) +
    '=' +
    encodeURIComponent(value) +
    expires +
    '; path=/;domain=' +
    hostname;
};

export const getFromCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts: any[] = value.split(`; ${name}=`);

  if (parts.length !== 2) {
    return '';
  }

  return parts.pop().split(';').shift();
};
