import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthState, AuthUser, LoginPayload } from './interface';

const initialState: AuthState = {
  user: null,
  roles: null,
  permissions: null,
  modules: null,
  company: null,
  companies: localStorage.getItem('companies')
    ? JSON.parse(localStorage.getItem('companies') || '')
    : null,
  globalSearch: localStorage.getItem('globalSearch') || '',
  sessionLoading: false,
  sessionRequested: false,
  sessionError: null,
  loginLoading: false,
  csrf: '',
  globalSettings: null,
  notifications: {
    list: [],
    has_failed: false,
    latest_success: null
  },
  messages: {
    list: [],
    unread: 0,
    processing: false
  }
};

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authorize(state, action: PayloadAction<AuthUser>) {
      state.user = action.payload;
      state.sessionLoading = false;
      localStorage.setItem('auth', JSON.stringify(action.payload));
    },
    unauthorize(state) {
      state.user = null;
      state.sessionLoading = false;
      localStorage.clear();
    },
    authRequest(state, action?: PayloadAction) {
      if (!action?.payload) {
        state.sessionRequested = true;
        state.sessionLoading = true;
      }
    },
    setRoles(state, action?: PayloadAction) {
      state.roles = action?.payload || [];
      localStorage.setItem('roles', JSON.stringify(action?.payload));
    },
    setPermissions(state, action?: PayloadAction) {
      state.permissions = action?.payload || [];
      localStorage.setItem('permissions', JSON.stringify(action?.payload));
    },
    setModules(state, action?: PayloadAction) {
      state.modules = action?.payload || [];
      localStorage.setItem('modules', JSON.stringify(action?.payload));
    },
    setCompany(state, action?: PayloadAction) {
      state.company = action?.payload || null;
      localStorage.setItem('company', JSON.stringify(action?.payload));
    },
    setCompanies(state, action?: PayloadAction) {
      state.companies = action?.payload || [];
      localStorage.setItem('companies', JSON.stringify(action?.payload) || '[]');
    },
    setGlobalSearch(state, action?: PayloadAction) {
      state.globalSearch = action?.payload || '';
      localStorage.setItem('globalSearch', action?.payload || '');
    },
    setSessionError(state, action?: PayloadAction) {
      state.sessionError = action?.payload || null;
    },
    setSessionLoading(state, action: PayloadAction<boolean>) {
      state.sessionLoading = action.payload;
    },
    setLoginLoading(state, action: PayloadAction<boolean>) {
      state.loginLoading = action.payload;
    },
    setCsrf(state, action: PayloadAction<string>) {
      state.csrf = action.payload;
    },
    setGlobalSettings(state, action?: PayloadAction) {
      state.globalSettings = action?.payload || null;
    },
    setNotifications(state, action?: PayloadAction<any>) {
      const { data, has_failed = false, latest_success = false } = action?.payload || {};

      state.notifications.list = data || null;
      state.notifications.has_failed = has_failed;
      state.notifications.latest_success = latest_success;
    },
    setOrderMessages(state, action?: PayloadAction<any>) {
      const { items, unread } = action?.payload || {};

      state.messages.list = items || [];
      state.messages.unread = unread || 0;
      state.messages.processing = true;
    },
    setOrderMessagesProcessing(state, action?: PayloadAction<any>) {
      state.messages.processing = action?.payload || false;
    }
  }
});

export const loginRequest = createAction<LoginPayload>('auth/loginRequest');
export const logoutRequest = createAction('auth/logoutRequest');
export const authRequest = createAction<boolean | undefined>('auth/authRequest');
export const refetchRequest = createAction<boolean | undefined>('auth/refetchRequest');
export const userModules = createAction('auth/userModules/fetching');
export const systemNotifications = createAction('auth/systemNotifications/fetching');
export const orderMessages = createAction('auth/orderMessages/fetching');
export const settingRequest = createAction('auth/settingRequest/fetching');

export const {
  authorize,
  unauthorize,
  setRoles,
  setPermissions,
  setModules,
  setCompany,
  setCompanies,
  setGlobalSearch,
  setSessionError,
  setLoginLoading,
  setSessionLoading,
  setCsrf,
  setGlobalSettings,
  setNotifications,
  setOrderMessages,
  setOrderMessagesProcessing
} = auth.actions;

export default auth.reducer;
