import * as React from 'react';
import { flowRight } from 'lodash';

import withNavbar from 'hocs/withNavbar';

import { Wrapper } from 'views/Layout/styles';

import ProgressBarBuffer from 'components/ProgressBar/buffer';

const Fallback = () => (
  <Wrapper sx={{ position: 'fixed', top: '0px' }}>
    <ProgressBarBuffer />
  </Wrapper>
);

export default flowRight(withNavbar)(Fallback)({
  wrapped: false
});
