import { call, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { _fetchSuppliers, fetchSuppliers, setDataError } from './reducer';

import axios from 'services/axios';

//=================================
// Fetch Suppliers
//=================================
function* FetchSuppliers({ payload }: PayloadAction) {
  try {
    const { data } = yield call(axios.request, {
      url: `/suppliers`,
      method: 'GET'
    });

    yield put(_fetchSuppliers({ data }));
  } catch (err: any) {
    yield put(setDataError(err?.response));
  }
}

export default function* rootSaga() {
  yield takeLatest(fetchSuppliers.type, FetchSuppliers);
}
