import * as React from 'react';
import { clone, debounce, flowRight } from 'lodash';

import {
  StyledSearchContainer,
  StyledSearchInput,
  StyledClearIcon,
  StyledInputBase,
  StyledContent,
  StyledListItem,
  StyledLink,
  StyledTitle,
  StyledCheckbox
} from './styles';

import { Close } from '@mui/icons-material';
import DialogContentText from '@mui/material/DialogContentText';

import Button from 'components/Button';
import withDialog from 'hocs/withDialog';
import { WrappedDialog } from 'types/dialog';

type SearchDialogProps = {
  placeholder?: string;
  list?: any;
  action?: string;
  selectable?: boolean;
  selected?: any[];
};

function Search(props: WrappedDialog<SearchDialogProps> & SearchDialogProps) {
  const {
    formState,
    formHandlers,
    placeholder = 'Search',
    list = [],
    action = 'Search',
    selectable = false
  } = props;

  const { fields } = formState;
  const { onChange } = formHandlers;

  const [search, setSearch] = React.useState<any>('');
  const [items, setItems] = React.useState<any>(list);
  const [selected, setSelected] = React.useState<any>(fields?.selected || []);

  const handleOnClickItem = (event: React.MouseEvent<HTMLElement>, item: any) => {
    event.preventDefault();

    if (fields?.selected?.includes(item)) {
      const filtered = clone(selected).filter((checked: any) => {
        return checked.id !== item.id;
      });
      onChange('selected', filtered);
      setSelected(filtered);
    } else {
      onChange('selected', [...selected, item]);
      setSelected((prev: any) => [...prev, item]);
    }

    if (selectable) {
      event.stopPropagation();
      return false;
    }

    event.stopPropagation();
    item.onClick();
  };

  const handleOnSelectItem = (event: React.ChangeEvent<HTMLInputElement>, item: any) => {
    event.preventDefault();
    if (event.target.checked) {
      onChange('selected', [...selected, item]);
      setSelected((prev: any) => [...prev, item]);
    } else {
      const filtered = clone(selected).filter((checked: any) => {
        return checked.id !== item.id;
      });
      onChange('selected', filtered);
      setSelected(filtered);
    }
  };

  React.useEffect(() => {
    if (search && search !== '') {
      const filtered = list.filter((item: any) => {
        return item.name?.toLowerCase().includes(search);
      });

      setItems(filtered);
    } else {
      setItems(list);
    }
  }, [list, search]);

  return (
    <React.Fragment>
      <StyledTitle id="scroll-dialog-title">
        <StyledSearchContainer direction="row">
          <StyledSearchInput sx={{ boxShadow: 3 }}>
            <StyledClearIcon
              className="close-icon"
              onClick={debounce((event) => {
                const inputBase = document.querySelector('.FilterSearchInput');
                const filterInput = inputBase?.getElementsByClassName(
                  'MuiInputBase-input'
                )[0] as HTMLInputElement;

                if (filterInput) {
                  filterInput.value = '';
                }

                setSearch('');
              })}>
              <Close />
            </StyledClearIcon>
            <StyledInputBase
              placeholder={placeholder}
              inputProps={{ 'aria-label': placeholder }}
              sx={{ width: '100%' }}
              onChange={debounce((event) => setSearch(event?.target?.value?.toLowerCase()), 1000)}
              className="FilterSearchInput"
            />
          </StyledSearchInput>
        </StyledSearchContainer>
      </StyledTitle>
      <StyledContent dividers>
        <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
          {items.map((item: any, key: any) => {
            return (
              <StyledListItem
                key={key}
                disablePadding
                chosen={selected.includes(item) ? 'true' : 'false'}
                onClick={(e) => handleOnClickItem(e, item)}>
                <StyledLink>{item.name}</StyledLink>
                {selectable && (
                  <StyledCheckbox
                    id={item.id}
                    color="primary"
                    checked={fields?.selected?.includes(item)}
                    name={item.name}
                    onChange={(e: any) => handleOnSelectItem(e, item)}
                    inputProps={{
                      'aria-label': 'select all desserts'
                    }}
                  />
                )}
              </StyledListItem>
            );
          })}
        </DialogContentText>
      </StyledContent>
    </React.Fragment>
  );
}

export default flowRight(withDialog())(Search);
