import * as React from 'react';

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { BreadcrumbsCollapsedIconSlotPropsOverrides } from '@mui/material';

type ProgressBarBufferProps = {
  restart?: BreadcrumbsCollapsedIconSlotPropsOverrides;
  interval?: number;
  completion?: number;
  increment?: number;
  shouldWait?: boolean;
  variant?: 'determinate' | 'indeterminate' | 'buffer' | 'query';
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'inherit';
  fn?: (result: boolean) => void;
};

export default function ProgressBarBuffer(props: ProgressBarBufferProps) {
  const {
    restart = false,
    interval = 500,
    increment = 25,
    completion = 0,
    shouldWait = false,
    variant = 'determinate',
    color = 'primary',
    fn
  } = props;

  const [progress, setProgress] = React.useState(completion);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (completion > progress + increment) {
      setProgress(completion);
    }
  }, [completion]);

  React.useEffect(() => {
    if (restart) {
      setProgress(0);
    }
  }, [restart]);

  React.useEffect(() => {
    fn && fn(loading);
  }, [loading]);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((value) => {
        if (shouldWait && loading && value >= 75) {
          return Math.min(value + 0.05, 100);
        }

        if (value === 100) {
          setLoading(false);
          clearInterval(timer);
          return 100;
        }

        return Math.min(value + increment, 100);
      });
    }, interval);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgress variant={variant} value={progress} color={color} />
    </Box>
  );
}
