import { createTheme } from '@mui/material/styles';

const colors = {
  primary: {
    opaque: '#BBDEFB',
    light: '#42A5F5',
    main: '#1976D2',
    dark: '#0C1139',
    fade: 'rgba(25, 118, 210, 0.04)'
  },
  secondary: {
    light: '#BA68C8',
    main: '#9C27B0',
    dark: '#7B1FA2'
  },
  error: {
    opaque: '#FAD1CF',
    light: '#EF5350',
    main: '#D32F2F',
    dark: '#C62828'
  },
  warning: {
    light: '#FEBF2C',
    main: '#ED6C02',
    dark: '#E65100',
    fade: 'rgba(255, 152, 0, 0.4)',
    faint: 'rgba(255, 152, 0, 0.05)'
  },
  info: {
    pale: '#4A78FA',
    light: '#03A9F4',
    main: '#0288D1',
    dark: '#01579B'
  },
  success: {
    light: '#4CAF50',
    main: '#2E7D32',
    dark: '#1B5E20',
    opaque: '#ACF8AD',
    fade: 'rgba(172, 248, 173, 0.2)'
  },
  gray: {
    dark: '#353B5A',
    gloom: '#AEAEAE',
    bluish: '#E2E3EB66',
    above: '#9E9E9E',
    suffice: 'rgba(53, 59, 90, 0.75)',
    average: 'rgba(53, 59, 90, 0.4)',
    medium: '#C9CBD7',
    normal: '#E6E7ED',
    light: '#FAFAFD',
    opaque: '#A3A2A3'
  },
  smoke: '#F5F5F5',
  white: '#FFFFFF',
  black: '#000000',
  rose: '#FF38C0',
  yellow: '#FEBF2C',
  orange: '#FFA500',
  blue: '#2196f3'
};

const theme = createTheme({
  colors,
  palette: { ...colors }
});

export default theme;
