import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './saga';
import rootReducer from './reducer';

const sagaMiddleware = createSagaMiddleware();
const store = configureStore({
  reducer: rootReducer,
  preloadedState: {},
  middleware: [sagaMiddleware]
});

sagaMiddleware.run(rootSaga);
export default store;
