import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import Typography from '@mui/material/Typography';

import IconMenu from './IconMenu';
import { useDispatch } from 'react-redux';
import { clearSearch, setPageHistory, setTableFilter } from 'store/data/reducer';
import { InitialTableFilterValue } from './DesktopMenu';

type MenuProps = {
  pages: any;
  menu: any;
};

export default function AppBarMobileMenu(props: MenuProps) {
  const { pages, menu } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <IconMenu id="icon-dropdown-mobile-menu" styles={{ mr: 1 }} menuItems={menu} type="mobile" />
      <Typography
        variant="h5"
        noWrap
        component="a"
        href="#"
        sx={{
          mr: 2,
          flexGrow: 1,
          fontWeight: 500,
          color: 'inherit',
          textDecoration: 'none',
          fontSize: '1.75rem'
        }}
        onClick={() => {
          dispatch(setTableFilter(InitialTableFilterValue));
          dispatch(clearSearch());
          dispatch(setPageHistory([]));
          navigate('/portal');
        }}>
        {process.env.REACT_APP_NAME}
      </Typography>
    </React.Fragment>
  );
}
