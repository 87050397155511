export const DISABLE = 'disable';
export const READ = 'read';
export const WRITE = 'write';
export const ADMIN = 'admin';
export const ACCESS = 'access';

export const PRODUCTS = 'products';
export const PRODUCTION_DEMAND = 'production_demand';
export const PRICE_AGREEMENTS = 'price_agreements';
export const DELIVERIES = 'deliveries';
export const SPARE_PARTS = 'spare_parts';
export const DOCUMENTS = 'documents';
export const MERIT_INTEGRATION = 'merit_integration';
export const INVOICES = 'invoices'; // NOTE: Only read access
export const USERS_AND_ACCESSES = 'users_and_accesses';
export const WARRANTY_CASES = 'warranty_cases';
export const SETTINGS = 'settings';
export const BMF_ACCESS = 'bmf_access'; // NOTE: DEPRECATED
export const ORDERS = 'orders';

// disabled
export const HAS_NO_ACCESS_TO_PRODUCTS = 'has_no_access_to_products';
export const HAS_NO_ACCESS_TO_PRODUCTION_DEMAND = 'has_no_access_to_production_demand';
export const HAS_NO_ACCESS_TO_PRICE_AGREEMENTS = 'has_no_access_to_price_agreements';
export const HAS_NO_ACCESS_TO_ORDERS = 'has_no_access_to_orders';
export const HAS_NO_ACCESS_TO_DELIVERIES = 'has_no_access_to_deliveries';
export const HAS_NO_ACCESS_TO_SPARE_PARTS = 'has_no_access_to_spare_parts';
export const HAS_NO_ACCESS_TO_DOCUMENTS = 'has_no_access_to_documents';
export const HAS_NO_ACCESS_TO_MERIT_INTEGRATION = 'has_no_access_to_merit_integration';
export const HAS_NO_ACCESS_TO_INVOICES = 'has_no_access_to_invoices';
export const HAS_NO_ACCESS_TO_USERS_AND_ACCESSES = 'has_no_access_to_users_and_accesses';
export const HAS_NO_ACCESS_TO_WARRANTY_CASES = 'has_no_access_to_warranty_cases';
export const HAS_NO_ACCESS_TO_SETTINGS = 'has_no_access_to_settings';

// read
export const CAN_VIEW_PRODUCTS = 'can_view_products';
export const CAN_VIEW_PRODUCTION_DEMAND = 'can_view_production_demand';
export const CAN_VIEW_PRICE_AGREEMENTS = 'can_view_price_agreements';
export const CAN_VIEW_ORDERS = 'can_view_orders';
export const CAN_VIEW_DELIVERIES = 'can_view_deliveries';
export const CAN_VIEW_SPARE_PARTS = 'can_view_spare_parts';
export const CAN_VIEW_DOCUMENTS = 'can_view_documents';
export const CAN_VIEW_MERIT_INTEGRATION = 'can_view_merit_integration';
export const CAN_VIEW_INVOICES = 'can_view_invoices';
export const CAN_VIEW_USERS_AND_ACCESSES = 'can_view_users_and_accesses';
export const CAN_VIEW_WARRANTY_CASES = 'can_view_warranty_cases';
export const CAN_VIEW_SETTINGS = 'can_view_settings';

// write
export const CAN_EDIT_PRODUCTS = 'can_edit_products';
export const CAN_EDIT_PRODUCTION_DEMAND = 'can_edit_production_demand';
export const CAN_EDIT_PRICE_AGREEMENTS = 'can_edit_price_agreements';
export const CAN_EDIT_ORDERS = 'can_edit_orders';
export const CAN_EDIT_DELIVERIES = 'can_edit_deliveries';
export const CAN_EDIT_SPARE_PARTS = 'can_edit_spare_parts';
export const CAN_EDIT_DOCUMENTS = 'can_edit_documents';
export const CAN_EDIT_MERIT_INTEGRATION = 'can_edit_merit_integration';
export const CAN_EDIT_USERS_AND_ACCESSES = 'can_edit_users_and_accesses';
export const CAN_EDIT_WARRANTY_CASES = 'can_edit_warranty_cases';
export const CAN_EDIT_SETTINGS = 'can_edit_settings';

// delete
export const CAN_DELETE_DELIVERIES = 'can_delete_deliveries';
export const CAN_DELETE_ORDERS = 'can_delete_orders';
export const CAN_DELETE_PRICE_AGREEMENTS = 'can_delete_price_agreements';
export const CAN_DELETE_USERS_AND_ACCESSES = 'can_delete_users_and_accesses';

// admin
export const HAS_ADMIN_ACCESS_TO_PRODUCTS = 'has_admin_access_to_products';
export const HAS_ADMIN_ACCESS_TO_PRODUCTION_DEMAND = 'has_admin_access_to_production_demand';
export const HAS_ADMIN_ACCESS_TO_PRICE_AGREEMENTS = 'has_admin_access_to_price_agreements';
export const HAS_ADMIN_ACCESS_TO_ORDERS = 'has_admin_access_to_orders';
export const HAS_ADMIN_ACCESS_TO_DELIVERIES = 'has_admin_access_to_deliveries';
export const HAS_ADMIN_ACCESS_TO_SPARE_PARTS = 'has_admin_access_to_spare_parts';
export const HAS_ADMIN_ACCESS_TO_DOCUMENTS = 'has_admin_access_to_documents';
export const HAS_ADMIN_ACCESS_TO_MERIT_INTEGRATION = 'has_admin_access_to_merit_integration';
export const HAS_ADMIN_ACCESS_TO_USERS_AND_ACCESSES = 'has_admin_access_to_users_and_accesses';
export const HAS_ADMIN_ACCESS_TO_WARRANTY_CASES = 'has_admin_access_to_warranty_cases';
export const HAS_ADMIN_ACCESS_TO_SETTINGS = 'has_admin_access_to_settings';

// access
export const HAS_USER_ACCESS_TO_PRODUCTION_DEMAND = 'has_user_access_to_production_demand';
export const CAN_ADD_ORDERS = 'can_add_orders';
export const CAN_ADD_DELIVERIES = 'can_add_deliveries';
export const HAS_USER_ACCESS_TO_USERS_AND_ACCESSES = 'has_user_access_to_users_and_accesses';
export const CAN_ADD_OR_EDIT_USERS = 'can_add_or_edit_users';
export const HAS_USER_ACCESS_TO_DELIVERIES = 'has_user_access_to_deliveries';
export const HAS_USER_ACCESS_TO_PRICE_AGREEMENTS = 'has_user_access_to_price_agreements';
export const HAS_USER_ACCESS_TO_SPARE_PARTS = 'has_user_access_to_spare_parts';
export const HAS_USER_ACCESS_TO_SETTINGS = 'has_user_access_to_settings';
export const HAS_USER_ACCESS_TO_DOCUMENTS = 'has_user_access_to_documents';
export const HAS_USER_ACCESS_TO_INVOICES = 'has_user_access_to_invoices';
export const HAS_USER_ACCESS_TO_ORDERS = 'has_user_access_to_orders';

export const PERMISSIONS = [DISABLE, READ, WRITE, ADMIN, ACCESS];
export const MODULES = [
  PRODUCTS,
  PRODUCTION_DEMAND,
  PRICE_AGREEMENTS,
  DELIVERIES,
  SPARE_PARTS,
  DOCUMENTS,
  MERIT_INTEGRATION,
  INVOICES,
  USERS_AND_ACCESSES,
  WARRANTY_CASES,
  SETTINGS,
  BMF_ACCESS
];

export const MODULE_PERMISSIONS_STACK = [
  {
    key: PRODUCTS,
    label: 'Products'
  },
  {
    key: DELIVERIES,
    label: 'Deliveries'
  },
  {
    key: WARRANTY_CASES,
    label: 'Warranty Cases'
  },
  {
    key: PRODUCTION_DEMAND,
    label: 'Production Demand'
  },
  {
    key: DOCUMENTS,
    label: 'Documents'
  },
  {
    key: USERS_AND_ACCESSES,
    label: 'Users & Accesses'
  },
  {
    key: PRICE_AGREEMENTS,
    label: 'Price agreements'
  },
  {
    key: MERIT_INTEGRATION,
    label: 'Merit Integration'
  },
  {
    key: SETTINGS,
    label: 'Settings'
  },
  {
    key: INVOICES,
    label: 'Invoices'
  },
  {
    key: SPARE_PARTS,
    label: 'Spare Parts'
  },
  {
    key: ORDERS,
    label: 'Orders'
  }
];

export const ACCESS_RIGHTS_STACK = [
  [
    {
      key: HAS_USER_ACCESS_TO_PRODUCTION_DEMAND,
      label: 'Production Demand'
    },
    {
      key: HAS_USER_ACCESS_TO_DELIVERIES,
      label: 'Deliveries'
    },
    {
      key: CAN_ADD_DELIVERIES,
      label: 'Add Deliveries'
    }
  ],
  [
    {
      key: HAS_USER_ACCESS_TO_DOCUMENTS,
      label: 'Documents'
    },
    {
      key: HAS_USER_ACCESS_TO_PRICE_AGREEMENTS,
      label: 'Price agreements'
    },
    {
      key: HAS_USER_ACCESS_TO_SPARE_PARTS,
      label: 'Spare parts'
    }
  ],
  [
    {
      key: HAS_USER_ACCESS_TO_USERS_AND_ACCESSES,
      label: 'Users & accesses'
    },
    {
      key: CAN_ADD_OR_EDIT_USERS,
      label: 'Add/edit users'
    },
    {
      key: HAS_USER_ACCESS_TO_ORDERS,
      label: 'Orders'
    }
  ]
];
