import * as React from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';

import Layout from 'views/Layout';
import { LayoutProps } from 'types/layout';
import Fallback from 'components/Fallback';

const renderRoute = (props: LayoutProps) => {
  const { children, ...restProps } = props;

  return (
    <Container disableGutters maxWidth={false} sx={{ minHeight: '100%' }}>
      <CssBaseline enableColorScheme />
      <Layout {...restProps}>
        <React.Suspense fallback={<Fallback />}>{children}</React.Suspense>
      </Layout>
    </Container>
  );
};

export default renderRoute;
