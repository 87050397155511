import * as React from 'react';
import flowRight from 'lodash/flowRight';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useTheme } from '@mui/material';
import Snackbar from 'components/Snackbar';
import { MainLayout, Wrapper } from './styles';

import { GlobalState } from 'store/reducer';
import { authSelector } from 'store/auth/selector';
import { fetchSuppliers } from 'store/data/reducer';
import { logoutRequest } from 'store/auth/reducer';
import { hideAllDialogs } from 'store/app/reducer';

import withAuth from 'hocs/withAuth';
import { LayoutProps } from 'types/layout';
import { getCookie } from 'services/cookie';

function Main(props: LayoutProps) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const location = useLocation();

  const { children, pageId, className, bgColor = theme.colors.gray.bluish } = props;

  const toast = useSelector((state: GlobalState) => state.app.toast);
  const dialog = useSelector((state: GlobalState) => state.app.dialog);
  const { auth, roles } = useSelector(authSelector);

  React.useEffect(() => {
    if (auth && !roles?.includes('supplier')) {
      dispatch(fetchSuppliers());
    }
  }, [auth, roles]);

  React.useEffect(() => {
    if (auth) {
      const interval: any = setInterval(() => {
        if (!getCookie('XSRF-TOKEN')) {
          clearInterval(interval);
          dispatch(logoutRequest());
        }
      }, 1000);

      return () => {
        if (interval !== 0) {
          clearInterval(interval);
        }
      };
    }
  }, [auth]);

  const DialogComponent = React.useMemo(() => {
    return dialog?.component;
  }, [dialog]);

  React.useEffect(() => {
    dispatch(hideAllDialogs());
  }, [location]);

  return (
    <MainLayout className={`page page-${pageId} ${className}`}>
      {DialogComponent && dialog && <DialogComponent {...dialog.props} />}
      {toast && <Snackbar toast={toast} delay={toast?.delay || 7000} withUndo={toast?.withUndo} />}
      <Wrapper id="wrapper" sx={{ backgroundColor: bgColor }}>
        {children}
      </Wrapper>
    </MainLayout>
  );
}

export default flowRight(withAuth)(Main);
