import { call, put, putResolve, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import {
  authorize,
  unauthorize,
  authRequest,
  loginRequest,
  logoutRequest,
  userModules,
  setLoginLoading,
  setSessionError,
  setSessionLoading,
  setRoles,
  setPermissions,
  setModules,
  setCompany,
  setCsrf,
  setGlobalSettings,
  settingRequest,
  setNotifications,
  systemNotifications,
  setOrderMessages,
  orderMessages
} from './reducer';

import axios from 'services/axios';
import history from 'services/history';
import { getCookie } from 'services/cookie';

interface LoginPayload {
  email: string;
  password: string;
}

//=================================
// Login User
//=================================
function* LoginUser(action: PayloadAction<LoginPayload>) {
  try {
    yield put(setSessionError());
  } catch (err: any) {
    yield put(setSessionError(err?.response));
  } finally {
    yield putResolve(setLoginLoading(false));
  }
}

//=================================
// Logout User
//=================================
function* LogoutUser() {
  try {
    yield call(axios.request, {
      url: '/logout',
      method: 'POST'
    });

    yield put(setSessionLoading(true));
    yield put(unauthorize());
  } catch (err: any) {
    yield put(setSessionError(err?.response));
    history.push('/login');
  } finally {
    history.push('/login');
  }
}

//=================================
// Fetch Current User
//=================================
function* GetUserSession() {
  try {
    yield call(axios.request, {
      url: '/sanctum/csrf-cookie'
    });

    yield put(setCsrf(getCookie(`XSRF-TOKEN`)));

    if (localStorage.getItem('access_token')) {
      const { user, data, settings, notifications, messages } = yield call(axios.request, {
        url: '/users/current'
      });

      yield put(authorize(user));
      yield put(setRoles(data?.user_roles));
      yield put(setPermissions(data?.user_permissions));
      yield put(setModules(data?.user_modules));
      yield put(setCompany(data?.user_supplier));
      yield put(setGlobalSettings(settings));
      yield put(setNotifications({ data: notifications }));
      yield put(setOrderMessages(messages));
    }
  } catch (err: any) {
    yield put(setSessionError(err?.response));
    yield put(setSessionLoading(false));
    history.push('/login');
  }
}

//=================================
// Fetch Current User's Module
//=================================
function* FetchUserModules() {
  try {
    const { data } = yield call(axios.request, {
      url: `/users/modules`,
      method: 'GET'
    });

    yield put(setModules(data));
  } catch (err: any) {
    yield put(setSessionError(err?.response));
  }
}

//=================================
// Fetch System Notifications
//=================================
function* FetchSystemNotifications() {
  try {
    const { data, has_failed, latest_success } = yield call(axios.request, {
      url: `/users/notifications`,
      method: 'GET'
    });

    yield put(setNotifications({ data, has_failed, latest_success }));
  } catch (err: any) {
    yield put(setSessionError(err?.response));
  }
}

//=================================
// Fetch OrderMessages
//=================================
function* FetchOrderMessages() {
  try {
    const { data } = yield call(axios.request, {
      url: `/users/messages`,
      method: 'GET'
    });

    yield put(setOrderMessages(data));
  } catch (err: any) {
    yield put(setSessionError(err?.response));
  }
}

//=================================
// Fetch Accounts Global Setting
//=================================
function* FetchGlobalSetting() {
  try {
    const { data } = yield call(axios.request, {
      url: `/setting/global/plain`,
      method: 'GET'
    });

    yield put(setGlobalSettings(data));
  } catch (err: any) {
    yield put(setSessionError(err?.response));
  }
}

function* rootSaga() {
  yield takeLatest(loginRequest.type, LoginUser);
  yield takeLatest(logoutRequest.type, LogoutUser);
  yield takeLatest(authRequest.type, GetUserSession);
  yield takeLatest(userModules.type, FetchUserModules);
  yield takeLatest(systemNotifications.type, FetchSystemNotifications);
  yield takeLatest(orderMessages.type, FetchOrderMessages);
  yield takeLatest(settingRequest.type, FetchGlobalSetting);
}

export default rootSaga;
