import { createSelector } from '@reduxjs/toolkit';
import { GlobalState } from 'store/reducer';

export const dataSelector = createSelector(
  (state: GlobalState) => state.data,
  (data) => {
    return {
      suppliers: {
        list: data?.suppliers?.list,
        selected: data?.suppliers?.selected,
        fetching: data?.suppliers?.fetching
      },
      files: data.files,
      popover: data.popover,
      product: data.product,
      order: data.order,
      changeLogs: data?.changeLogs,
      agreementFields: data?.agreementFields,
      agreementProducts: data?.agreementProducts,
      warrantyDetailUpdates: data?.warrantyDetailUpdates
    };
  }
);
