import * as React from 'react';

import { StyledButton, StyledCircularProgress } from './styles';
import { setFileUpload } from 'store/data/reducer';
import { useDispatch } from 'react-redux';

export type ButtonProps = {
  isLoading?: boolean;
  text?: string;
  textcolor?: string;
  hoverColor?: string;
  styles?: object;
  width?: string | number | null;
  fullWidth?: boolean;
  maxWidth?: string;
  variant?: string;
  type?: string;
  hidden?: boolean;
  disabled?: boolean;
  isFileUpload?: boolean;
  onClick?: () => void;
};

export default function Button(props: ButtonProps & any) {
  const {
    isLoading = false,
    text = '',
    styles = {},
    width = null,
    fullWidth = true,
    maxWidth = '60%',
    variant = 'contained',
    type = 'submit',
    onClick = () => {},
    hoverColor = '',
    hidden = false,
    disabled = false,
    isFileUpload = false,
    ...restProps
  } = props;

  const inputFile = React.useRef<HTMLInputElement | null>(null);
  const dispatch = useDispatch();

  const onHandleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (inputFile.current && isFileUpload) {
      inputFile.current.click();
    }

    if (!isFileUpload) {
      event.preventDefault();
      event.stopPropagation();
      onClick();
    }
  };

  const onHandleFileUpload = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    dispatch(setFileUpload([file] as any));
  };

  return (
    <StyledButton
      {...restProps}
      type={type}
      width={width}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      variant={variant}
      sx={styles}
      onClick={onHandleClick}
      hoverColor={hoverColor}
      hidden={hidden}
      disabled={disabled}>
      <input
        type="file"
        id="file"
        ref={inputFile}
        style={{ display: 'none' }}
        onChange={onHandleFileUpload}
      />
      {isLoading ? <StyledCircularProgress variant="indeterminate" /> : <span>{text}</span>}
    </StyledButton>
  );
}
