import { ReactNode } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import { StyledSearch, SearchIconWrapper, StyledInputBase } from './styles';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import theme from 'theme';

type SearchProps = {
  dropdown?: boolean;
  placeholder?: string;
  icon?: ReactNode;
  value?: string;
  action?: () => void;
  onChange: (value: string) => void;
};

export default function AppBarSearch(props: SearchProps) {
  const {
    action,
    dropdown = false,
    placeholder = 'Search',
    icon = <SearchIcon />,
    onChange = () => {},
    value = ''
  } = props;

  return (
    <StyledSearch onClick={action}>
      <StyledInputBase
        placeholder={placeholder}
        inputProps={{ 'aria-label': 'search' }}
        width={dropdown ? '140px' : '100%'}
        value={value}
        onChange={(event) => onChange(event.target.value)}
      />
      <SearchIconWrapper>
        {value !== '' && (
          <IconButton onClick={() => onChange('')}>
            <CloseIcon sx={{ fontSize: '1.5rem', color: theme.colors.gray.medium }} />
          </IconButton>
        )}
        {icon}
      </SearchIconWrapper>
    </StyledSearch>
  );
}
