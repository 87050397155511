import * as React from 'react';
import { RouterProvider } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';

import theme from './theme';
import store from './store';
import router from './router';
import { ErrorBoundary } from 'components/ErrorBoundary';

const App = () => {
  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <ReduxProvider store={store}>
          <RouterProvider router={router} />
        </ReduxProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default App;
