import * as React from 'react';

import omit from 'lodash/omit';
import set from 'lodash/set';

import { FormErrors, FormValid, UseFormParams } from 'types/form';

export type FormHandlers = {
  onChange: (key: string, value: any) => void;
  onElementChange: (value: any, e: any) => void | undefined;
  onValidate: (data?: any) => void;
  onReset: () => void;
  onSetError: (errors: FormErrors) => void;
  onSetForm: (data?: any) => void;
};

export default function useForm<S>(params: UseFormParams<S>) {
  const {
    initialFields,
    validator = (): FormValid => ({ isValid: true, errors: {} }),
    customChangeHandler = {},
    onValid = () => {},
    isInitialLoad = false
  } = params;

  const [fields, setFields] = React.useState<S>(initialFields);
  const [errors, setErrors] = React.useState<FormErrors>({});

  function onElementChange(value: any, e: any): void | undefined {
    let id = '';
    if (typeof e === 'string') {
      id = e;
    } else if (e && e.target) {
      id = e.target.id || '';
      if (e && e.target && e.target.type === 'number' && !Number.isNaN(value)) {
        value = Number(value);
      }
    }
    if (id) {
      onChange(id, value);
    }
  }

  function onSetForm(data: S) {
    setFields(data);
  }

  function onSetError(errors: FormErrors) {
    setErrors(errors);
  }

  React.useEffect(() => {
    if (isInitialLoad) {
      setFields(initialFields);
    }
  }, [isInitialLoad, initialFields]);

  return [
    {
      fields,
      errors
    },
    {
      onElementChange,
      onChange,
      onValidate,
      onReset,
      onSetForm,
      onSetError
    }
  ] as const;

  function onChange(key: string, value: any): void {
    const customHandler = customChangeHandler[key];

    if (customHandler) {
      setFields((oldFields) => {
        const changes = customHandler(value, oldFields);
        if (changes) {
          return { ...oldFields, ...changes };
        }
        return oldFields;
      });
      return;
    }
    setErrors(omit(errors, key));
    setFields((oldFields: any) => {
      return set({ ...oldFields }, key, value);
    });
  }

  function onValidate(data?: S): void {
    const { isValid, errors: validationErrors = {} } = validator({ ...fields, ...(data || {}) });

    if (isValid) {
      setErrors({});
      onValid({ ...fields, ...(data || {}) });
      return;
    }
    setErrors(validationErrors);
  }

  function onReset() {
    setErrors({});
    setFields(initialFields);
  }
}
