import * as React from 'react';

import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterNoneIcon from '@mui/icons-material/FilterNone';

import {
  StyledSearch,
  SearchIconWrapper,
  StyledInputBase,
  StyledChip,
  StyledClearIcon,
  StyledStartAdornment,
  StyledEndAdornment,
  FilterIconContainer
} from './styles';

import { Clear } from '@mui/icons-material';
import { useTheme } from '@mui/material';

type SelectableItem = {
  key: string | number;
  label: string;
  classes?: string;
};

type SearchProps = {
  dropdown?: boolean;
  placeholder?: string;
  icon?: React.ReactNode;
  onChange?: (value: string) => void;
  onClick?: () => void;
  onSearchClear?: () => void;
  onSelectClear?: () => void;
  width?: string | number;
  minWidth?: number;
  showCount?: boolean;
  select?: boolean;
  selected?: SelectableItem[];
  selectClearable?: boolean;
  searchClearable?: boolean;
  disabled?: boolean;
  hideCaret?: boolean;
  inputValue?: string;
};

export default function SearchInput(props: SearchProps) {
  const {
    placeholder = 'Search',
    icon = <SearchIcon />,
    onChange = () => {},
    onClick = () => {},
    onSearchClear = () => {},
    onSelectClear = () => {},
    width = 160,
    minWidth,
    showCount = false,
    select = false,
    selected = [],
    selectClearable = true,
    searchClearable = false,
    disabled = false,
    hideCaret = false,
    inputValue = ''
  } = props;

  const theme = useTheme();

  const [value, setValue] = React.useState<string>(inputValue);

  const handleOnClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    onClick();
  };

  const handleOnChange = (value: string) => {
    setValue(value);
    onChange(value);
  };

  const handleOnSelectClear = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    onSelectClear();

    event.stopPropagation();
  };

  const handleOnSearchClear = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setValue('');
    handleOnChange('');
    onSearchClear();
  };

  const startAdornment = React.useMemo(() => {
    if (selected && selected.length === 0) {
      return null;
    }

    const selectedItems = selected.map((item: SelectableItem) => (
      <StyledChip
        key={item.key}
        tabIndex={-1}
        size="small"
        label={item.label}
        className={item?.classes || ''}
      />
    ));

    return (
      <StyledStartAdornment>
        {showCount && (
          <div className="selected-items">
            <StyledChip
              key={selected.length}
              tabIndex={-1}
              size="small"
              label={`${selected.length} supplier${selected.length > 1 ? 's' : ''} selected`}
            />
          </div>
        )}
        {!showCount && <div className="selected-items">{selectedItems}</div>}
        {selectClearable && (
          <StyledClearIcon className="close-icon" onClick={handleOnSelectClear}>
            <Clear />
          </StyledClearIcon>
        )}
      </StyledStartAdornment>
    ) as React.ReactNode;
  }, [disabled, selected, selectClearable]);

  const endAdornment = React.useMemo(() => {
    return (
      <StyledEndAdornment>
        {searchClearable && value !== '' && (
          <StyledClearIcon className="close-icon" onClick={handleOnSearchClear}>
            <Clear />
          </StyledClearIcon>
        )}
      </StyledEndAdornment>
    ) as React.ReactNode;
  }, [searchClearable, value]);

  return (
    <StyledSearch
      id="StyledSearchElement"
      disabled={disabled}
      select={select}
      hideCaret={hideCaret}
      onClick={handleOnClick}>
      <SearchIconWrapper className={select ? 'DesktopFilter' : ''}>{icon}</SearchIconWrapper>
      <StyledInputBase
        placeholder={!startAdornment ? placeholder : ''}
        inputProps={{ 'aria-label': 'search' }}
        inputWidth={startAdornment ? '0px' : width.toString()}
        minWidth={minWidth}
        startAdornment={select ? startAdornment : null}
        endAdornment={endAdornment}
        value={value}
        disabled={disabled}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          handleOnChange(event.target.value)
        }
        className={select ? 'DesktopFilter' : ''}
        hideInput={select && selected.length > 0}
      />
      {select ? (
        selected.length > 0 ? (
          <FilterIconContainer id="FilterIconContainer">
            <FilterNoneIcon
              className={select ? 'MobileFilter' : ''}
              sx={{ width: '100%', height: '100%', color: theme.colors.gray.medium }}
            />
            <span className={selected.length > 9 ? 'TwoDigit' : ''}>
              {selected.length > 9 ? '9+' : selected.length}
            </span>
          </FilterIconContainer>
        ) : (
          <FilterAltIcon
            className={select ? 'MobileFilter' : ''}
            sx={{ width: '23px', height: '23px', color: theme.colors.gray.medium, padding: '3px' }}
          />
        )
      ) : (
        <></>
      )}
    </StyledSearch>
  );
}
