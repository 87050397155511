import React from 'react';
import { pick } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import { DialogActions, useMediaQuery, useTheme } from '@mui/material';

import Button from 'components/Button';

import { dialogProcessing, hideDialog } from 'store/app/reducer';

import { WithDialogProps, WrappedDialog } from 'types/dialog';
import useForm from 'hooks/useForm';
import { GlobalState } from 'store/reducer';

import breakpoints from 'types/breakpoints';

export default () => {
  return function withDialog<T>(WrappedComponent: React.FC<WrappedDialog<T>>) {
    function DialogLayout(props: WithDialogProps<T>) {
      const {
        initialFields,
        title,
        contentStyles,
        hasContinue = false,
        isCaution = false,
        continueLabel = 'Submit',
        continueColor = 'primary',
        hasCancel = true,
        cancelLabel = 'Close',
        disableDefaultAction = false,
        buttonLoading = false,
        width,
        ...restProps
      } = props;

      const theme = useTheme();
      const dispatch = useDispatch();
      const [formState, formHandlers] = useForm<T>({ ...pick(props), onValid, initialFields });
      const isProcessing = useSelector((state: GlobalState) => state.app.dialogProcessing);
      const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

      function onCancel() {
        dispatch(hideDialog());
      }

      function onValid(arg: T) {
        dispatch(dialogProcessing(true));
        props.onValid && props.onValid(arg);
      }

      function onContinue() {
        if (!isProcessing) {
          formHandlers.onValidate();
        }
      }

      function onExtra() {
        if (!isProcessing) {
          formHandlers.onValidate();
        }
      }

      React.useEffect(() => {
        const keyDownHandler = (event: KeyboardEvent) => {
          if (event.key === 'Enter') {
            event.preventDefault();
            if (!isProcessing) {
              formHandlers.onValidate();
            }
          }
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
          document.removeEventListener('keydown', keyDownHandler);
        };
      }, [formHandlers, isProcessing]);

      return (
        <StyledDialog
          id="Styled-Dialog"
          open
          keepMounted
          fullScreen={fullScreen}
          width={String(width)}
          onClose={props.onClose || onCancel}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          scroll={'paper'}>
          <WrappedComponent
            {...restProps}
            formState={formState}
            formHandlers={formHandlers}
            onContinue={onContinue}
            onExtra={onExtra}
            onCancel={onCancel}
            onClose={onCancel}
          />
          {!disableDefaultAction && (
            <StyledActions>
              {hasCancel && (
                <Button
                  fullWidth={false}
                  variant="text"
                  text={cancelLabel}
                  textcolor="black"
                  onClick={onCancel}
                  styles={{ alignSelf: 'end', marginTop: '24px' }}
                  id="CloseButtonButton"
                />
              )}
              {hasContinue && (
                <StyledContinueButton
                  isCaution={isCaution}
                  fullWidth={false}
                  text={continueLabel}
                  onClick={onContinue}
                  styles={{ alignSelf: 'end', marginTop: '24px', backgroundColor: continueColor }}
                  isLoading={isProcessing || buttonLoading}
                />
              )}
            </StyledActions>
          )}
        </StyledDialog>
      );
    }

    return DialogLayout;
  };
};

export const StyledDialog = styled(Dialog)<{ width: string }>`
  padding: 24px;

  .MuiDialog-container .MuiPaper-root:not(.MuiAlert-root) {
    min-width: unset;
    ${breakpoints.md} {
      min-width: 680px;
    }
    ${({ width }) =>
      width
        ? `
      width: ${width};
      min-width: unset;
      max-width: unset
      `
        : ''}
  }
`;

export const StyledActions = styled(DialogActions)`
  display: flex;
  justify-content: flex-end;
  padding-top: 0px !important;
  padding-bottom: 24px;

  button {
    font-weight: 600;
    padding: 0px 24px;
    margin-right: 12px;
    height: 36px !important;
  }
`;

export const StyledContinueButton = styled(Button)<{ isCaution?: boolean }>`
  ${({ isCaution, theme }) =>
    isCaution &&
    `
  background-color: ${theme.colors.error.light};
  &:hover {
    background-color: ${theme.colors.error.dark};
  }
`}
`;
