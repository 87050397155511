import { combineReducers } from 'redux';
import app from './app/reducer';
import auth from './auth/reducer';
import data from './data/reducer';

const rootReducer = combineReducers({
  app,
  auth,
  data
});

export type GlobalState = ReturnType<typeof rootReducer>;

export default rootReducer;
