import { styled } from '@mui/material/styles';
import Box from '@mui/material/Grid';

export const MainLayout = styled('main')`
  display: flex;
  height: 100%;
  width: 100%;
`;

export const Wrapper = styled(Box)`
  display: flex;
  height: 100%;
  width: 100%;
`;
