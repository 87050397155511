import { PayloadAction } from '@reduxjs/toolkit';
import { AppState, Dialog } from '../interface';

function updateDialogProps(state: AppState, { payload }: PayloadAction<{ props: object }>) {
  if (state.dialog) {
    state.dialog.props = {
      ...state.dialog.props,
      ...payload.props
    };
  }
}

function minimizeDialogs(state: AppState, { payload }: PayloadAction<Dialog>) {
  let { dialog, temporaryDialogs, minimizeDialogs } = state;

  if (dialog) {
    minimizeDialogs = [...minimizeDialogs, { ...dialog, minimizeName: payload }];
  }

  if (temporaryDialogs.length) {
    [dialog] = [...temporaryDialogs].reverse();
    temporaryDialogs = temporaryDialogs.slice(0, temporaryDialogs.length - 1);
  } else {
    dialog = null;
  }

  state.dialog = dialog;
  state.minimizeDialogs = minimizeDialogs;
}

function showMinimizeDialogs(state: AppState, { payload }: PayloadAction<Dialog>) {
  const { dialog } = state;
  let { temporaryDialogs, minimizeDialogs } = state;

  if (minimizeDialogs.length && payload) {
    const minimizeIndex = minimizeDialogs.findIndex((each: any) => each.minimizeName === payload);
    if (minimizeIndex !== -1) {
      state.dialog = minimizeDialogs[minimizeIndex];
      minimizeDialogs = minimizeDialogs.filter((e: any, index: number) => index !== minimizeIndex);

      if (dialog) {
        temporaryDialogs = [...temporaryDialogs, dialog];
      }
    }
  }

  state.minimizeDialogs = minimizeDialogs;
  state.temporaryDialogs = temporaryDialogs;
}

function showDialog(state: AppState, { payload }: PayloadAction<Dialog>) {
  const { dialog } = state;
  let { temporaryDialogs, minimizeDialogs } = state;
  if (dialog) {
    temporaryDialogs = [...temporaryDialogs, dialog];
  }

  if (minimizeDialogs.length && payload.props.title) {
    const minimizeIndex = minimizeDialogs.findIndex(
      (each: any) => each.minimizeName === payload.props.title
    );
    if (minimizeIndex !== -1) {
      state.dialog = minimizeDialogs[minimizeIndex];
      minimizeDialogs = minimizeDialogs.filter((e: any, index: number) => index !== minimizeIndex);
    } else {
      state.dialog = payload;
    }
  } else {
    state.dialog = payload;
  }

  state.minimizeDialogs = minimizeDialogs;
  state.temporaryDialogs = temporaryDialogs;
}

function hideDialog(state: AppState) {
  let dialog = null;
  let { temporaryDialogs } = state;

  if (temporaryDialogs.length) {
    [dialog] = [...temporaryDialogs].reverse();
    temporaryDialogs = temporaryDialogs.slice(0, temporaryDialogs.length - 1);
  }
  state.dialog = dialog;
  state.dialogProcessing = false;
  state.temporaryDialogs = temporaryDialogs;
}

function hideAllDialogs(state: AppState) {
  state.dialog = null;
  state.temporaryDialogs = [];
  state.minimizeDialogs = [];
  state.dialogProcessing = false;
}

function dialogProcessing(state: AppState, action: PayloadAction<boolean>) {
  const { dialog } = state;
  if (dialog && action.payload) {
    state.dialogProcessing = action.payload;
  } else if (!action.payload) {
    state.dialogProcessing = action.payload;
  }
}

export const dialogActions = {
  updateDialogProps,
  minimizeDialogs,
  showMinimizeDialogs,
  showDialog,
  hideDialog,
  hideAllDialogs,
  dialogProcessing
};
