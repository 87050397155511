import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

import { authRequest } from 'store/auth/reducer';
import { authSelector } from 'store/auth/selector';

import ProgressBarBuffer from 'components/ProgressBar/buffer';

type AuthProps = {
  requireAuth: any;
  roles: any;
  forbidden?: string;
  pageId?: string;
};

function withAuth(WrappedComponent: any) {
  function AuthLayout(props: AuthProps) {
    const token = localStorage.getItem('access_token');
    const { requireAuth = false, forbidden = '' } = props;
    const { auth, permissions, sessionLoading, sessionRequested } = useSelector(authSelector);

    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
      if (!sessionRequested) {
        dispatch(authRequest());
      }
    }, []);

    if (token && sessionLoading) {
      return <ProgressBarBuffer />;
    }

    if (!auth && sessionRequested && requireAuth) {
      return <Navigate to="/login" replace state={{ path: location.pathname }} />;
    }

    if ((props?.pageId === 'login' || props?.pageId === '') && auth) {
      return <Navigate to="/portal" replace state={{ path: location.pathname }} />;
    }

    if (forbidden !== '' && permissions?.includes(forbidden)) {
      return <Navigate to="/forbidden" replace state={{ path: location.pathname }} />;
    }

    return <WrappedComponent {...props} />;
  }

  return AuthLayout;
}

export default withAuth;
