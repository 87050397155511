import * as React from 'react';
import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Company } from 'store/auth/interface';
import { authSelector } from 'store/auth/selector';
import { dataSelector } from 'store/data/selector';
import { setCompanies } from 'store/auth/reducer';
import { hideAllDialogs, showDialog } from 'store/app/reducer';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';

import AppBar from 'components/AppBar';
import SearchDialog from 'components/Dialog/search';

import { dropdown, pages } from 'services/menu';
import { filterMenu } from 'services/utils';

import breakpoints from 'types/breakpoints';

type PageLayoutParams = {
  wrapped?: boolean;
  permitted_roles?: string[];
};

function withNavbar(WrappedComponent: any) {
  return (params: PageLayoutParams) => {
    return function Wrapper(props: any) {
      return <PageLayout {...{ ...params, ...props }} />;
    };
  };

  function PageLayout(props: PageLayoutParams & any) {
    const { auth, roles, modules, company, companies, globalSearch } = useSelector(authSelector);
    const dispatch = useDispatch();
    const { suppliers } = useSelector(dataSelector);
    const { permitted_roles, wrapped = true } = props;

    const selectedCompanies = React.useMemo(() => {
      return suppliers.list.filter((item: any) => {
        return companies?.some((id) => id === item.id);
      });
    }, [suppliers, companies]);

    const [selected, setSelected] = React.useState<any[]>(selectedCompanies);

    const orderedSupplierList = React.useMemo(() => {
      if (selectedCompanies.length < 1 && selected.length < 1) return suppliers.list;
      const filteredSupplier = suppliers.list.filter(
        (item) => !companies?.some((id) => id === item.id)
      );
      const selectedSuppliers = [...(selected || selectedCompanies)];
      selectedSuppliers.sort((a, b) => a.name.localeCompare(b.name));
      return [...selectedSuppliers, ...filteredSupplier];
    }, [selectedCompanies, selected, suppliers, companies]);

    const handleOnClick = () => {
      if (suppliers.list?.length == 0) {
        return false;
      }

      dispatch(
        showDialog({
          component: SearchDialog,
          props: {
            hasContinue: true,
            selectable: true,
            list: orderedSupplierList,
            initialFields: {
              selected: selected
            },
            onValid: (data: any) => {
              setSelected(() => data.selected);
              const companyIds = data.selected?.map((item: Company) => item.id);
              dispatch(setCompanies(companyIds));
              dispatch(hideAllDialogs());
            }
          }
        })
      );
    };

    const group = React.useMemo(() => {
      if (roles?.includes('admin')) {
        return 'Super Admin';
      }

      if (roles?.includes('bmf')) {
        return 'BMF';
      }

      return company?.name || '';
    }, [company, roles]);

    const isForbidden = React.useMemo(() => {
      if (permitted_roles) {
        const isPermitted = roles?.some((role: string) => permitted_roles.includes(role));
        return !isPermitted;
      }

      return false;
    }, [permitted_roles, roles]);

    if (isForbidden) {
      return <Navigate to="/portal" replace state={{ path: location.pathname }} />;
    }

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
        {auth && (
          <React.Fragment>
            <AppBar
              user={{ name: auth?.full_name || '', group: group }}
              isSupplier={!['Super Admin', 'BMF'].includes(group)}
              navbar={filterMenu(modules, pages, roles)}
              sidebar={filterMenu(modules, dropdown, roles)}
              searchPlaceholder="Search product..."
              onSearch={handleOnClick}
              companies={selectedCompanies || selected}
              textSearch={globalSearch}
              onClear={() => {
                setSelected([]);
                dispatch(setCompanies());
              }}
            />
            <Toolbar />
          </React.Fragment>
        )}
        {auth && wrapped ? (
          <StyledBox id="AuthWrappedContainer">
            <StyledPaper elevation={3}>
              <WrappedComponent {...props} />
            </StyledPaper>
          </StyledBox>
        ) : (
          <WrappedComponent {...props} />
        )}
      </Box>
    );
  }

  return PageLayout;
}

const StyledBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 34px;
  padding-bottom: 18px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin-top: 44px;
    padding-bottom: 28px;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-top: 64px;
    padding-bottom: 48px;
  }
`;

const StyledPaper = styled(Paper)`
  padding: 8px 16px;
  width: 95%;
  margin-bottom: 12px;

  ${breakpoints.xl} {
    width: 90%;
  }

  ${breakpoints.xxl} {
    width: 80%;
  }
`;

export default withNavbar;
