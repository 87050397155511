import { all, fork } from 'redux-saga/effects';
import auth from './auth/effects';
import app from './app/effects';
import data from './data/effects';

function* rootSaga() {
  yield all([auth, app, data].map(fork));
}

export default rootSaga;
