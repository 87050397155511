import { styled } from '@mui/material/styles';

import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';

import breakpoints from 'types/breakpoints';

export const StyledButton = styled(Button)<{
  textcolor?: string;
  width?: string | number;
  hoverColor?: string;
  hidden?: boolean;
  maxWidth?: string;
}>`
  ${({ hidden }) => (hidden ? `display: none;` : '')}
  height: 40px;
  color: ${({ textcolor }) => textcolor} !important;
  max-width: ${({ maxWidth }) => maxWidth ?? '60%'} !important;
  text-wrap: nowrap;
  ${breakpoints.md} {
    width: ${({ width }) =>
      width ? (typeof width === 'string' ? width : `${width}px`) : 'auto'} !important;
  }

  &:hover {
    ${({ theme, hoverColor }) => (hoverColor ? `color: ${theme.colors.white} !important` : '')};
    ${({ hoverColor }) => (hoverColor ? `background: ${hoverColor} !important` : '')};
  }
`;

export const StyledCircularProgress = styled(CircularProgress)`
  color: ${({ theme }) => theme.colors.gray.light};

  height: 20px !important;
  width: 20px !important;
`;

export const StyledBackButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  color: ${({ theme }) => theme.palette.common.black};

  margin-bottom: 0px;
  min-width: 56px;

  padding: 8px 12px;
  column-gap: 5px;
`;

export const StyledButtonText = styled('span')<{ disabled?: boolean }>`
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.gray.normal : theme.palette.common.black};

  font-size: 16px;
  font-weight: 600;
  width: 100%;

  &.DesktopText {
    display: none;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    &.DesktopText {
      display: flex;
      margin-left: 4px;
    }
  }
`;

export const BadgeContainer = styled(Badge)`
  display: flex;
  justify-content: flex-end;
  width: 46px;

  .MuiBadge-badge {
    top: 6px;
    font-size: 10px;
    border-radius: 20px;
    max-width: 20px;
  }
`;

export const ModalButtonContainer = styled(Button)<{
  fontcolor?: string;
  backgroundcolor?: string;
  hoverColor?: string;
  width?: string;
}>`
  color: ${({ fontcolor, theme }) => fontcolor || theme.colors.white};
  background-color: ${({ backgroundcolor }) => backgroundcolor || 'inherit'};
  width: ${({ width }) => width || 'fit-content'};

  &:hover {
    background-color: ${({ hoverColor }) => hoverColor};
  }
`;

export const ModalButtonContent = styled('span')<{ position?: string }>`
  display: flex;
  flex-direction: ${({ position }) => (position === 'end' ? 'row' : 'row-reverse')};
  width: fit-content;
  column-gap: 5px;
  align-items: center;
  justify-content: center;
`;

export const StyledIconButton = styled(IconButton)<{ disabled?: boolean }>`
  svg {
    fill: ${({ disabled, theme }) =>
      disabled ? theme.colors.gray.normal : theme.colors.gray.suffice};
  }
`;
