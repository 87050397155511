import React from 'react';
import { retryPageLoading } from './actions';

import Fallback from 'components/Fallback';

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, { hasError: boolean }> {
  constructor(props: Readonly<ErrorBoundaryProps>) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: unknown, info: unknown) {
    retryPageLoading();
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return <Fallback />;
    }

    return this.props.children;
  }
}
