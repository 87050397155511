import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';
import store from 'store';
import { Toast } from 'store/app/interface';

import { unauthorize } from 'store/auth/reducer';
import { getResponseMessage } from './utils';
import { addToast, hideAllDialogs } from 'store/app/reducer';

const baseURL = `${process.env.REACT_APP_API_URL}/api`;
const axiosInstance = axios.create({
  baseURL,
  withCredentials: true
});

axiosInstance.interceptors.request.use(
  function (config: InternalAxiosRequestConfig) {
    /* XSRF for safari */
    config.headers['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`;
    config.headers['X-XSRF-TOKEN'] = store.getState().auth.csrf;
    return config;
  },
  function (err: Error) {
    return Promise.reject(err);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error: AxiosError) {
    const { response } = error;

    const exclusions = ["This account doesn't exist.", 'File not found.'];
    const showErrorMessage = (message: any) => {
      const notification: Toast = {
        message,
        type: 'error'
      };

      if (exclusions.includes(message)) {
        store.dispatch(addToast(notification));
      }
    };

    switch (response?.status) {
      // client errors
      case 405:
      case 413:
      case 429: {
        showErrorMessage(getResponseMessage(response));
        store.dispatch(hideAllDialogs());
        return Promise.reject(error);
      }

      // token mismatch
      case 419: {
        showErrorMessage(getResponseMessage(response));
        window.location.reload();
        return Promise.reject(error);
      }

      // server errors
      case 500:
      case 501:
      case 502:
      case 504: {
        showErrorMessage(
          'Something went wrong. Please try again or contact support for assistance.'
        );
        return Promise.reject(error);
      }

      // common errors
      case 400: {
        showErrorMessage(getResponseMessage(response));
        return Promise.reject(error);
      }

      // unauthorized
      case 401: {
        showErrorMessage(getResponseMessage(response));
        store.dispatch(unauthorize());
        return Promise.reject(error);
      }

      // default
      default: {
        if (response) {
          showErrorMessage(getResponseMessage(response));
          return Promise.reject(error);
        }

        return Promise.resolve(response);
      }
    }
  }
);

export default axiosInstance;
