import { takeLatest, put, call, delay } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'services/axios';

import {
  dialogProcessing,
  formProcessing,
  showSuccess,
  hideDialog as hideDialogAction
} from './reducer';

interface MutationPayload {
  hideDialog?: boolean;
  password: string;
  successMessage: string;
  callback: (params: any) => void;
  callbackDelay: number;
  formType: string;
  requestConfig: AxiosRequestConfig;
}

//=================================
// API Queries
//=================================
function* Mutation(action: PayloadAction<MutationPayload>) {
  try {
    const { payload } = action;
    const {
      hideDialog = true,
      requestConfig,
      successMessage,
      callback = () => {},
      formType = 'dialog',
      callbackDelay = 0
    } = payload;

    if (formType === 'dialog') {
      yield put(dialogProcessing(true));
    } else {
      yield put(formProcessing(true));
    }

    const response: AxiosResponse = yield call(axios.request, requestConfig);
    yield put(showSuccess({ hideDialog, message: successMessage }));
    if (hideDialog) {
      yield put(hideDialogAction());
    }
    if (callbackDelay) {
      yield delay(callbackDelay);
    }
    callback(response);
  } catch (err: any) {
    yield put(err);
  }
}

function* rootSaga() {
  yield takeLatest('MUTATION_REQUESTED', Mutation);
}

export default rootSaga;
