import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { orderMessages } from 'store/auth/reducer';
import { authSelector } from 'store/auth/selector';
import { formProcessing } from 'store/app/reducer';

import useMutation from 'hooks/useMutation';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export default function Messages() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const { messages } = useSelector(authSelector);
  const open = Boolean(anchorEl);

  const [, onUpdate] = useMutation({
    method: 'POST',
    showMessage: false,
    shouldUpdateList: true,
    onSuccess() {
      dispatch(formProcessing(false));
      dispatch(orderMessages());
    },
    onError() {
      dispatch(formProcessing(false));
    }
  });

  const items = React.useMemo(() => {
    return messages.list;
  }, [messages]);

  const onHandleShow = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onHandleClose = () => {
    setAnchorEl(null);
  };

  const handleClickMessage = (event: any, id: any, messageId: any) => {
    event.stopPropagation();
    event.preventDefault();

    onUpdate({ url: `/orders/${id}/messages/${messageId}/read` });

    navigate(`/orders/${id}`);
    setAnchorEl(null);
  };

  const truncateContent = (text: string, max = 128) => {
    const truncatedText = text.length > max ? `${text.slice(0, max)}…` : text;
    return <div className="content">{truncatedText}</div>;
  };

  return (
    <NotificationContainer>
      <IconButton
        size="large"
        aria-label="notification"
        aria-controls="menu-appbar"
        color="inherit"
        className="notification"
        onClick={onHandleShow}>
        <Badge badgeContent={messages?.unread || 0} color="error">
          <MailIcon />
        </Badge>
      </IconButton>
      <MenuContainer
        id="notification-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={onHandleClose}
        onClick={onHandleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        {items && items.length > 0 ? (
          items?.map((item: any, idx: any) => {
            return (
              <MenuItemContainer
                key={idx + item.elapsed_time}
                onClick={(e) => handleClickMessage(e, item.order_id, item.id)}>
                <IconContainer>
                  <Badge variant="dot" color="error" className="badge-dot" invisible={item.read_at}>
                    <NotificationsIcon />
                  </Badge>
                </IconContainer>
                <ContentContainer>
                  <div className="header">{'Order Nr: ' + item.order_nr}</div>
                  {truncateContent(item.content)}
                  <div className="footer">{item.elapsed_time + ' ago'}</div>
                </ContentContainer>
              </MenuItemContainer>
            );
          })
        ) : (
          <MenuItemContainer
            sx={{
              flexDirection: 'column',
              padding: '48px 16px',
              alignItems: 'center',
              justifyContent: 'center',
              rowGap: '12px',
              cursor: 'default'
            }}>
            <span>{'No messages available'}</span>
          </MenuItemContainer>
        )}
      </MenuContainer>
    </NotificationContainer>
  );
}

const NotificationContainer = styled(Box)<{ hasNotifications?: boolean }>`
  display: flex !important;

  .notification {
    cursor: pointer !important;
    padding: 0px;
    padding-left: 12px;

    ${({ theme }) => theme.breakpoints.up('md')} {
      padding: 12px;
    }
  }
`;

const MenuContainer = styled(Menu)`
  .MuiList-root {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
`;

const MenuItemContainer = styled(MenuItem)`
  display: flex;
  flex-direction: row;
  gap: 8px;

  width: 360px;
  padding-top: 20px;
  padding-bottom: 16px;
  padding-right: 20px;
  white-space: initial;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray.normal};
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 480px;
  }
`;

const IconContainer = styled(Box)`
  display: flex;
  width: 30px;
  margin-left: 4px;

  * {
    font-size: 26px;
    color: ${({ theme }) => theme.colors.primary.main};
  }

  .MuiBadge-dot {
    right: 4px;
    top: 2px;
  }
`;

const ContentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  width: 360px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 480px;
  }

  .header {
    font-size: 16px;
    font-weight: 600;
  }

  .content {
    font-size: 16px;
    margin-top: 4px;
  }

  .footer {
    margin-top: 8px;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.gray.above};
  }
`;
