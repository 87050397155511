import { styled, alpha } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import InputBase from '@mui/material/InputBase';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import breakpoints from 'types/breakpoints';

export const StyledDialog = styled(Dialog)`
  width: 100%;
  padding: 6px;
`;

export const StyledTitle = styled(DialogTitle)`
  padding: 24px;
  padding-bottom: 0px !important;
`;

export const StyledSearchContainer = styled(Stack)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  width: 100%;
  height: 100%;
`;

export const StyledSearchInput = styled('div')`
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  border: 1px solid ${({ theme }) => theme.colors.gray.medium} !important;
  background-color: ${({ theme }) => alpha(theme.palette.common.white, 0.85)};
  padding-left: 6px;

  color: ${({ theme }) => theme.colors.gray.dark};
  margin-left: 0;
  width: 100%;
  min-height: 42px;
  max-height: 42px;

  &:hover {
    background-color: ${({ theme }) => alpha(theme.palette.common.white, 1)};
  }
`;

export const StyledClearIcon = styled('div')`
  display: flex;
  padding: ${({ theme }) => theme.spacing(1, 1)};
  height: 100%;
  position: relative;
  align-items: center;
  justify-content: center;
  cursor: pointer !important;
  padding: 0px 8px;

  svg {
    color: ${({ theme }) => theme.colors.gray.medium};
  }

  &:hover {
    svg {
      color: ${({ theme }) => theme.colors.gray.dark};
    }
  }
`;

export const StyledInputBase = styled(InputBase)<{ width?: string }>`
  color: inherit;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;

  .MuiInputBase-input {
    width: 40%;
    padding: 0 12px;
    text-overflow: ellipsis;
    ${breakpoints.md} {
      width: ${({ width }) => width ?? '160px'};
    }
  }
`;

export const StyledContent = styled(DialogContent)`
  .MuiListItem-root {
    font-size: 14px;
    padding: 12px 4px;
    border-top: 1px solid ${({ theme }) => theme.colors.gray.medium} !important;

    &:first-of-type {
      border-top: none !important;
    }
  }
`;

export const StyledActions = styled(DialogActions)`
  display: flex;
  justify-content: flex-end;
  padding-top: 0px;
  padding-bottom: 24px;
  height: 58px;

  button {
    font-weight: 600;
    padding: 0px 24px;
  }
`;

export const StyledListItem = styled(ListItem)<{ chosen?: string }>`
  display: flex;
  justify-content: space-between;
  background: ${({ theme, chosen }) => (chosen === 'true' ? theme.colors.gray.normal : 'none')};

  cursor: pointer;

  &:first-of-type {
    border-top: none !important;
  }

  &:last-of-type {
    border-bottom: none !important;
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  font-weight: 600;
  cursor: pointer;
`;

export const StyledCheckbox = styled(Checkbox)`
  padding: 0px;
`;

export const StyledAlertContainer = styled(Box)`
  width: 480px;
`;

export const StyledAlertTitle = styled(DialogTitle)`
  padding: 24px;
  font-weight: 600;
`;

export const StyledAlertContent = styled(DialogContent)`
  margin-top: 12px;
  margin-bottom: 24px;
  font-weight: 500;
`;
export const StyledAlertAction = styled(DialogActions)`
  margin: 12px;
`;

export const DownloadContainer = styled(Box)`
  display: flex;
  flex-direction: row;

  gap: 8px;
  padding: 24px 40px 16px 40px;

  align-items: center;
  justify-content: center;

  .MuiLinearProgress-root {
    width: 100%;
  }

  * {
    color: ${({ theme }) => theme.colors.primary.main};
  }
`;

export const ConfirmTitle = styled(DialogTitle)`
  padding: 20px;
  padding-bottom: 12px;
  font-size: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray.medium};
`;

export const ConfirmDialogContainer = styled(Box)`
  display: flex;
  margin: 0;
  flex-direction: column;
  padding-bottom: 12px;
`;

export const ConfirmDialogBodyContainer = styled(Box)<{ content?: string }>`
  display: flex;
  padding-top: 12px;
  margin: 16px 32px;
  font-weight: 500;
  font-size: 16px;

  color: ${({ theme, content }) => (content ? content : theme.colors.error.main)};
  min-height: 18px;
  flex-direction: column;

  .message {
    margin: auto 0;
  }

  .additional {
    margin-top: 12px;
    color: ${({ theme }) => theme.colors.error.main};
  }
`;
