import { styled, alpha } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import InputBase from '@mui/material/InputBase';

import breakpoints from 'types/breakpoints';

export const StyledSearch = styled('div')<{
  disabled?: boolean;
  width?: string;
  select?: boolean;
  hideCaret?: boolean;
}>`
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid rgba(0, 0, 0, 0.18);
  background-color: ${({ theme }) => alpha(theme.palette.common.white, 0.85)};
  color: ${({ theme }) => theme.colors.gray.dark};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-left: 0 !important;
  width: auto;
  justify-content: flex-start;

  &:hover {
    background-color: ${({ theme }) => alpha(theme.palette.common.white, 1)};
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-left: 16px !important;
  }

  input {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')} !important;
    text-overflow: ellipsis;

    ${({ hideCaret }) => hideCaret && `caret-color: transparent;`}
  }

  .DesktopFilter {
    display: none;
  }

  .MobileFilter {
    display: flex;
  }

  ${breakpoints.md} {
    .MobileFilter {
      display: none;
    }

    .DesktopFilter {
      display: flex;
    }
  }
`;

export const SearchIconWrapper = styled('div')`
  padding: ${({ theme }) => theme.spacing(1, 1)};
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: ${({ theme }) => theme.colors.gray.medium};
  }
`;

export const FilterIconContainer = styled(Box)`
  display: flex;
  position: relative;
  width: 23px;
  height: 23px;
  padding: 3px;
  span {
    font-size: 10px;
    position: absolute;
    color: #c9cbd7;
    top: 50%;
    left: 50%;
    transform: translate(-32%, -58%);
    font-weight: 500;

    &.TwoDigit {
      font-size: 10px;
      transform: translate(-34%, -57%);
    }
  }

  ${breakpoints.md} {
    display: none;
  }
`;

export const StyledInputBase = styled(InputBase)<{
  inputWidth?: string;
  hideInput?: boolean;
  minWidth?: number;
}>`
  color: inherit;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  width: 100%;

  .MuiInputBase-input {
    padding: ${({ theme }) => theme.spacing(1, 0, 1, 2)};
    transition: ${({ theme }) => theme.transitions.create('width')};

    padding-left: ${({ inputWidth }) => (inputWidth === '0px' ? '6px' : 'auto')};
    padding-right: ${({ theme, inputWidth }) =>
      inputWidth === '0px' ? '12px' : `calc(1em + ${theme.spacing(4)})`};
  }

  &.MuiInputBase-root {
    width: ${({ inputWidth }) => (inputWidth ? inputWidth === '0px' && '100%' : '160px')};
    ${({ minWidth }) => (minWidth ? `min-width: ${minWidth}px` : '')}
    ${({ hideInput }) =>
      hideInput
        ? `
          .MuiInputBase-input {
            display: none;
          }
          .MuiBox-root {
            padding: 3px 24px 4px 12px !important;
          }
          `
        : ``}
  }
`;

export const StyledChip = styled(Chip)`
  margin: 4px;
`;

export const StyledStartAdornment = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 12px;
  height: 100%;
  width: 100%;
  cursor: pointer;

  .selected-items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }
`;

export const StyledEndAdornment = styled(Box)`
  display: flex;
  justify-content: flex-end;
  padding: 0px 12px;
  margin-right: 24px;
  height: 100%;
  width: 8px;
  cursor: pointer;
`;

export const StyledClearIcon = styled('div')`
  display: flex;
  padding: ${({ theme }) => theme.spacing(1, 1)};
  height: 100%;
  position: relative;
  align-items: center;
  justify-content: center;
  cursor: pointer !important;
  padding: 0px 8px;
  z-index: 10;

  svg {
    color: ${({ theme }) => theme.colors.gray.medium};
  }

  &:hover {
    svg {
      color: ${({ theme }) => theme.colors.error.main};
    }
  }
`;
