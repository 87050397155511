import { createSelector } from '@reduxjs/toolkit';

import {
  CAN_ADD_DELIVERIES,
  CAN_ADD_OR_EDIT_USERS,
  CAN_EDIT_DOCUMENTS,
  CAN_EDIT_DELIVERIES,
  CAN_EDIT_PRICE_AGREEMENTS,
  CAN_EDIT_PRODUCTION_DEMAND,
  CAN_EDIT_PRODUCTS,
  CAN_EDIT_SETTINGS,
  CAN_EDIT_USERS_AND_ACCESSES,
  CAN_VIEW_DOCUMENTS,
  CAN_VIEW_DELIVERIES,
  CAN_VIEW_PRICE_AGREEMENTS,
  CAN_VIEW_PRODUCTION_DEMAND,
  CAN_VIEW_PRODUCTS,
  CAN_VIEW_SETTINGS,
  CAN_VIEW_USERS_AND_ACCESSES,
  HAS_ADMIN_ACCESS_TO_DOCUMENTS,
  HAS_ADMIN_ACCESS_TO_DELIVERIES,
  HAS_ADMIN_ACCESS_TO_PRICE_AGREEMENTS,
  HAS_ADMIN_ACCESS_TO_PRODUCTION_DEMAND,
  HAS_ADMIN_ACCESS_TO_PRODUCTS,
  HAS_ADMIN_ACCESS_TO_SETTINGS,
  HAS_ADMIN_ACCESS_TO_USERS_AND_ACCESSES,
  HAS_NO_ACCESS_TO_DOCUMENTS,
  HAS_NO_ACCESS_TO_DELIVERIES,
  HAS_NO_ACCESS_TO_PRICE_AGREEMENTS,
  HAS_NO_ACCESS_TO_PRODUCTION_DEMAND,
  HAS_NO_ACCESS_TO_PRODUCTS,
  HAS_NO_ACCESS_TO_SETTINGS,
  HAS_NO_ACCESS_TO_USERS_AND_ACCESSES,
  HAS_USER_ACCESS_TO_DELIVERIES,
  HAS_USER_ACCESS_TO_DOCUMENTS,
  HAS_USER_ACCESS_TO_PRICE_AGREEMENTS,
  HAS_USER_ACCESS_TO_PRODUCTION_DEMAND,
  HAS_USER_ACCESS_TO_SETTINGS,
  HAS_USER_ACCESS_TO_SPARE_PARTS,
  HAS_USER_ACCESS_TO_USERS_AND_ACCESSES,
  CAN_DELETE_DELIVERIES,
  CAN_DELETE_ORDERS,
  CAN_DELETE_USERS_AND_ACCESSES
} from 'services/permission';

import { GlobalState } from 'store/reducer';

export const permissionSelector = createSelector(
  (state: GlobalState) => state.auth.permissions,
  (permissions) => {
    const user_permissions = permissions || [];

    return {
      // disabled
      HAS_NO_ACCESS_TO_PRODUCTS: user_permissions.includes(HAS_NO_ACCESS_TO_PRODUCTS),
      HAS_NO_ACCESS_TO_PRODUCTION_DEMAND: user_permissions.includes(
        HAS_NO_ACCESS_TO_PRODUCTION_DEMAND
      ),
      HAS_NO_ACCESS_TO_PRICE_AGREEMENTS: user_permissions.includes(
        HAS_NO_ACCESS_TO_PRICE_AGREEMENTS
      ),
      HAS_NO_ACCESS_TO_DELIVERIES: user_permissions.includes(HAS_NO_ACCESS_TO_DELIVERIES),
      HAS_NO_ACCESS_TO_DOCUMENTS: user_permissions.includes(HAS_NO_ACCESS_TO_DOCUMENTS),
      HAS_NO_ACCESS_TO_SETTINGS: user_permissions.includes(HAS_NO_ACCESS_TO_SETTINGS),
      HAS_NO_ACCESS_TO_USERS_AND_ACCESSES: user_permissions.includes(
        HAS_NO_ACCESS_TO_USERS_AND_ACCESSES
      ),

      // read
      CAN_VIEW_PRODUCTS: user_permissions.includes(CAN_VIEW_PRODUCTS),
      CAN_VIEW_PRODUCTION_DEMAND: user_permissions.includes(CAN_VIEW_PRODUCTION_DEMAND),
      CAN_VIEW_PRICE_AGREEMENTS: user_permissions.includes(CAN_VIEW_PRICE_AGREEMENTS),
      CAN_VIEW_DELIVERIES: user_permissions.includes(CAN_VIEW_DELIVERIES),
      CAN_VIEW_DOCUMENTS: user_permissions.includes(CAN_VIEW_DOCUMENTS),
      CAN_VIEW_SETTINGS: user_permissions.includes(CAN_VIEW_SETTINGS),
      CAN_VIEW_USERS_AND_ACCESSES: user_permissions.includes(CAN_VIEW_USERS_AND_ACCESSES),

      // write
      CAN_EDIT_PRODUCTS: user_permissions.includes(CAN_EDIT_PRODUCTS),
      CAN_EDIT_PRODUCTION_DEMAND: user_permissions.includes(CAN_EDIT_PRODUCTION_DEMAND),
      CAN_EDIT_PRICE_AGREEMENTS: user_permissions.includes(CAN_EDIT_PRICE_AGREEMENTS),
      CAN_EDIT_DELIVERIES: user_permissions.includes(CAN_EDIT_DELIVERIES),
      CAN_EDIT_DOCUMENTS: user_permissions.includes(CAN_EDIT_DOCUMENTS),
      CAN_EDIT_SETTINGS: user_permissions.includes(CAN_EDIT_SETTINGS),
      CAN_EDIT_USERS_AND_ACCESSES: user_permissions.includes(CAN_EDIT_USERS_AND_ACCESSES),

      // admin
      HAS_ADMIN_ACCESS_TO_PRODUCTS: user_permissions.includes(HAS_ADMIN_ACCESS_TO_PRODUCTS),
      HAS_ADMIN_ACCESS_TO_PRODUCTION_DEMAND: user_permissions.includes(
        HAS_ADMIN_ACCESS_TO_PRODUCTION_DEMAND
      ),
      HAS_ADMIN_ACCESS_TO_PRICE_AGREEMENTS: user_permissions.includes(
        HAS_ADMIN_ACCESS_TO_PRICE_AGREEMENTS
      ),
      HAS_ADMIN_ACCESS_TO_DELIVERIES: user_permissions.includes(HAS_ADMIN_ACCESS_TO_DELIVERIES),
      HAS_ADMIN_ACCESS_TO_DOCUMENTS: user_permissions.includes(HAS_ADMIN_ACCESS_TO_DOCUMENTS),
      HAS_ADMIN_ACCESS_TO_SETTINGS: user_permissions.includes(HAS_ADMIN_ACCESS_TO_SETTINGS),
      HAS_ADMIN_ACCESS_TO_USERS_AND_ACCESSES: user_permissions.includes(
        HAS_ADMIN_ACCESS_TO_USERS_AND_ACCESSES
      ),

      // delete
      CAN_DELETE_DELIVERIES: user_permissions.includes(CAN_DELETE_DELIVERIES),
      CAN_DELETE_ORDERS: user_permissions.includes(CAN_DELETE_ORDERS),
      CAN_DELETE_USERS_AND_ACCESSES: user_permissions.includes(CAN_DELETE_USERS_AND_ACCESSES),

      // access
      HAS_USER_ACCESS_TO_PRODUCTION_DEMAND: user_permissions.includes(
        HAS_USER_ACCESS_TO_PRODUCTION_DEMAND
      ),
      CAN_ADD_DELIVERIES: user_permissions.includes(CAN_ADD_DELIVERIES),
      HAS_USER_ACCESS_TO_USERS_AND_ACCESSES: user_permissions.includes(
        HAS_USER_ACCESS_TO_USERS_AND_ACCESSES
      ),
      CAN_ADD_OR_EDIT_USERS: user_permissions.includes(CAN_ADD_OR_EDIT_USERS),
      HAS_USER_ACCESS_TO_DELIVERIES: user_permissions.includes(HAS_USER_ACCESS_TO_DELIVERIES),
      HAS_USER_ACCESS_TO_PRICE_AGREEMENTS: user_permissions.includes(
        HAS_USER_ACCESS_TO_PRICE_AGREEMENTS
      ),
      HAS_USER_ACCESS_TO_SPARE_PARTS: user_permissions.includes(HAS_USER_ACCESS_TO_SPARE_PARTS),
      HAS_USER_ACCESS_TO_SETTINGS: user_permissions.includes(HAS_USER_ACCESS_TO_SETTINGS),
      HAS_USER_ACCESS_TO_DOCUMENTS: user_permissions.includes(HAS_USER_ACCESS_TO_DOCUMENTS)
    };
  }
);

export const authSelector = createSelector(
  (state: GlobalState) => state.auth.user,
  (state: GlobalState) => state.auth.sessionLoading,
  (state: GlobalState) => state.auth.sessionRequested,
  (state: GlobalState) => state.auth.roles,
  (state: GlobalState) => state.auth.permissions,
  (state: GlobalState) => state.auth.modules,
  (state: GlobalState) => state.auth.company,
  (state: GlobalState) => state.auth.companies,
  (state: GlobalState) => state.auth.globalSearch,
  (state: GlobalState) => state.auth.globalSettings,
  (state: GlobalState) => state.auth.notifications,
  (state: GlobalState) => state.auth.messages,
  (
    auth,
    sessionLoading,
    sessionRequested,
    roles,
    permissions,
    modules,
    company,
    companies,
    globalSearch,
    globalSettings,
    notifications,
    messages
  ) => {
    return {
      auth,
      sessionLoading,
      sessionRequested,
      roles,
      permissions,
      modules,
      company,
      companies,
      globalSearch,
      globalSettings,
      notifications,
      messages
    };
  }
);
