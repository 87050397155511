import * as React from 'react';
import moment from 'services/moment';
import { useDispatch, useSelector } from 'react-redux';

import { systemNotifications } from 'store/auth/reducer';
import { authSelector } from 'store/auth/selector';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import { Error, NotificationImportant, PriorityHigh } from '@mui/icons-material';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export default function Notification() {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const { notifications } = useSelector(authSelector);
  const open = Boolean(anchorEl);

  const messages = React.useMemo(() => {
    return notifications.list
      ?.flatMap((item: any) => {
        return item.messages.map((message: any) => ({
          ...message,
          created_at: message.created_at,
          internal_error: message.description === 'Internal Server Error',
          elapsed_time_readable: item.elapsed_time_readable
        }));
      })
      ?.sort((a: any, b: any) => {
        return (
          moment(b.created_at, 'YYYY.MM.DD HH:mm').valueOf() -
          moment(a.created_at, 'YYYY.MM.DD HH:mm').valueOf()
        );
      });
  }, [notifications]);

  const onHandleShow = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (messages?.length > 0) {
      setAnchorEl(event.currentTarget);
    }
  };

  const onHandleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    if (open) {
      dispatch(systemNotifications());
    }
  }, [open]);

  return (
    <NotificationContainer hasNotifications={messages?.length > 0}>
      <IconButton
        size="large"
        aria-label="notification"
        aria-controls="menu-appbar"
        color="inherit"
        className="notification"
        onClick={onHandleShow}>
        <Badge variant="dot" color="error" invisible={messages?.length === 0}>
          <NotificationImportant />
        </Badge>
      </IconButton>
      <MenuContainer
        id="notification-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={onHandleClose}
        onClick={onHandleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        {messages?.map((item: any, idx: any) => {
          return (
            <MenuItemContainer key={idx}>
              <IconContainer>{item.internal_error ? <PriorityHigh /> : <Error />}</IconContainer>
              <ContentContainer>
                <div className="header">{item.description}</div>
                {!item.internal_error && <div className="content">{item.content}</div>}
                <div className="footer">
                  {item.model_type} • {item.elapsed_time_readable}
                </div>
              </ContentContainer>
            </MenuItemContainer>
          );
        })}
      </MenuContainer>
    </NotificationContainer>
  );
}

const NotificationContainer = styled(Box)<{ hasNotifications?: boolean }>`
  display: ${({ hasNotifications }) => (hasNotifications ? 'flex' : 'none')} !important;

  .notification {
    cursor: ${({ hasNotifications }) => (hasNotifications ? 'pointer' : 'default')} !important;
    padding: 0px;
    padding-left: 12px;

    ${({ theme }) => theme.breakpoints.up('md')} {
      padding: 12px;
    }
  }
`;

const MenuContainer = styled(Menu)`
  .MuiList-root {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
`;

const MenuItemContainer = styled(MenuItem)`
  display: flex;
  flex-direction: row;
  gap: 8px;

  width: 360px;
  padding-top: 20px;
  padding-bottom: 16px;
  padding-right: 20px;
  white-space: initial;
  cursor: default;

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray.normal};
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 480px;
  }
`;

const IconContainer = styled(Box)`
  display: flex;
  width: 30px;
  margin-left: 4px;

  * {
    font-size: 30px;
    color: ${({ theme }) => theme.colors.error.main};
  }
`;

const ContentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  width: 360px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 480px;
  }

  .header {
    font-size: 17px;
    font-weight: 600;
  }

  .content {
    font-size: 16px;
    margin-top: 4px;
  }

  .footer {
    margin-top: 8px;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.gray.above};
  }
`;
