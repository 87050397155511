import { PayloadAction } from '@reduxjs/toolkit';
import { AppState, Notification, DialogToast, Toast } from '../interface';

import { dialogActions } from './dialog';

function addToast(state: AppState, { payload }: PayloadAction<Toast>) {
  state.toast = payload;
}

function removeToast(state: AppState) {
  state.toast = null;
}

function hideNotification(state: AppState) {
  state.dialogToast = null;
}

function showConfirm(state: AppState, { payload }: PayloadAction<DialogToast>) {
  state.dialogToast = {
    ...payload,
    type: 'confirm',
    message: payload.title,
    subText: payload.message
  };

  state.formProcessing = false;
  state.dialogProcessing = false;
}

function showSuccess(
  state: AppState,
  { payload }: PayloadAction<Notification & { hideDialog?: boolean }>
) {
  const { hideDialog = true, onDismiss, ...rest } = payload;
  if (hideDialog) {
    dialogActions.hideDialog(state);
  }

  state.dialogToast = {
    type: 'success',
    message: rest.message,
    messageBody: rest.messageBody,
    subText: rest.subText,
    autohideTimeout: rest.autohideTimeout,
    onDismiss
  };
}

function showError(state: AppState, { payload }: PayloadAction<Error>) {
  state.dialogToast = {
    ...payload,
    type: 'error'
  };
  state.formProcessing = false;
  state.dialogProcessing = false;
}

export const alertActions = {
  addToast,
  removeToast,
  hideNotification,
  showConfirm,
  showError,
  showSuccess
};
