import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from './interface';

import { alertActions } from './actions/alert';
import { dialogActions } from './actions/dialog';

const initialState: AppState = {
  toast: null,
  media: '',
  dialogToast: null,
  dropdownMenu: null,
  popoverState: null,

  isUploading: false,
  isDownloading: false,
  isProcessing: false,
  isDropdownMenuVisible: false,

  dialog: null,
  minimizeDialogs: [],
  temporaryDialogs: [],

  outsideClick: false,
  triggerUpdating: false,
  triggerDeleting: null,
  forceUpdating: false,
  queryProcessing: false,
  formProcessing: false,
  dialogProcessing: false,
  modalProcessing: false
};

const app = createSlice({
  name: 'app',
  initialState,
  reducers: {
    // alerts
    addToast: alertActions.addToast,
    removeToast: alertActions.removeToast,
    hideNotification: alertActions.hideNotification,
    showConfirm: alertActions.showConfirm,
    showSuccess: alertActions.showSuccess,
    showError: alertActions.showError,

    // dialogs
    updateDialogProps: dialogActions.updateDialogProps,
    minimizeDialog: dialogActions.minimizeDialogs,
    showMinimizeDialog: dialogActions.showMinimizeDialogs,
    showDialog: dialogActions.showDialog,
    hideAllDialogs: dialogActions.hideAllDialogs,
    hideDialog: dialogActions.hideDialog,
    dialogProcessing: dialogActions.dialogProcessing,

    // extra actions
    setPopoverState(state, action: PayloadAction<any>) {
      state.popoverState = action.payload;
    },
    clearPopoverState(state) {
      state.popoverState = null;
    },
    triggerUpdating(state, action: PayloadAction<boolean>) {
      state.triggerUpdating = action.payload;
    },
    triggerDeleting(state, action: PayloadAction<any>) {
      state.triggerDeleting = action.payload;
    },
    forceUpdating(state, action: PayloadAction<boolean>) {
      state.forceUpdating = action.payload;
    },
    queryProcessing(state, action: PayloadAction<boolean>) {
      state.queryProcessing = action.payload;
    },
    formProcessing(state, action: PayloadAction<boolean>) {
      state.formProcessing = action.payload;
    },
    setOutsideClick(state, action: PayloadAction<boolean>) {
      state.outsideClick = action.payload;
    },
    clearLoading(state) {
      state.formProcessing = false;
      state.dialogProcessing = false;
    },
    changeMedia(state, { payload }: PayloadAction<string>) {
      state.media = payload;
    }
  }
});

export const {
  showDialog,
  hideDialog,
  dialogProcessing,
  hideAllDialogs,
  minimizeDialog,
  showMinimizeDialog,
  updateDialogProps,

  addToast,
  removeToast,
  hideNotification,
  showConfirm,
  showError,
  showSuccess,

  setOutsideClick,
  setPopoverState,
  clearPopoverState,
  triggerUpdating,
  triggerDeleting,
  forceUpdating,
  queryProcessing,
  formProcessing,
  clearLoading,
  changeMedia
} = app.actions;

export default app.reducer;
