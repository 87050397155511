import * as React from 'react';

import Collapse from '@mui/material/Collapse';
import MenuList from '@mui/material/MenuList';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpen from '@mui/icons-material/MenuOpen';

import IconMenuItem from './IconMenuItem';
import { StyledIconButton, StyledMenu } from './styles';
import { Identifiable } from 'types/identifiable';
import { DropdownMenu, DropdownMenuItem } from 'store/app/interface';

interface DeviceType {
  type?: 'mobile' | 'desktop';
}

export default function IconMenu(props: DropdownMenu & Identifiable & DeviceType) {
  const { styles, menuItems, type = 'desktop' } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
    } else {
      handleClose();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <StyledIconButton sx={styles} onClick={handleClick}>
        <Collapse orientation="horizontal" in={!anchorEl}>
          <MenuIcon />
        </Collapse>
        <Collapse orientation="horizontal" in={Boolean(anchorEl)}>
          <MenuOpen />
        </Collapse>
      </StyledIconButton>
      <StyledMenu
        id="basic-menu"
        className={type}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}>
        <MenuList>
          {menuItems &&
            menuItems.map((item: DropdownMenuItem, key: any) => {
              return <IconMenuItem {...item} key={key} title={item.title} />;
            })}
        </MenuList>
      </StyledMenu>
    </React.Fragment>
  );
}
