import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from 'store/reducer';
import { useNavigate as Navigate, type NavigateOptions, useLocation } from 'react-router-dom';
import { setPageHistory } from 'store/data/reducer';
import { useCallback, useEffect } from 'react';

function useNavigate() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useSelector((state: GlobalState) => state.data.pageHistory);

  const navigate = Navigate();

  const setHistory = (paths: string[]): void => {
    dispatch(setPageHistory(paths));
  };

  const navigateTo = (path: string, options?: NavigateOptions): void => {
    setHistory([...history, location.pathname]);
    navigate(path, options);
    return;
  };

  const navigateBack = useCallback((): void => {
    const length = history.length - 1;
    const last = history[length] ?? '/';
    navigate(last);
    setHistory(history?.filter((_, index) => index !== length));
    return;
  }, [history]);

  return {
    navigateTo,
    navigateBack
  };
}

export default useNavigate;
