export default {
  xs: ' @media (min-width: 300px)',
  sm: ' @media (min-width: 640px)',
  md: '@media (min-width: 768px)',
  lg: '@media (min-width: 1024px)',
  xl: '@media (min-width: 1440px)',
  xxl: '@media (min-width: 2560px)',

  // custom
  largeDevice: '@media (min-width: 1920px)'
};
